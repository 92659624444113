<template>
    <div class='safe'>
        <Form 
            @fillInfo="fillInfo" 
            @passwordModify="passwordModify" 
            @loginOut="loginOut"
            :formItems="formItems" :button="button"/>
    </div>
</template>
<script>
import { reactive, toRefs, inject } from 'vue'
import Form from '../../components/form.vue'
import { jumpTo, toast, getUserInfo, getVisitor, removeVistor, canWithdraw, setIsWhiteList} from '../../utils/util'
import { loginout } from '../../utils/api'
export default {
    name: '',
    components: {Form},
    setup() {
         const state= reactive({
             formItems: [
                 {label: '账户资料', type: 'button',tipColor: 'red', eventName: 'fillInfo', tip: '未完善', showArrow: true},
                 {label: '登录密码修改', type: 'button', tip: '登录密码修改', eventName: 'passwordModify', showArrow: true, passwordType:'FOUNDLOGINPWD'},
                //  {label: '投注密码修改', type: 'button', tip: '投注密码修改', eventName: 'passwordModify', showArrow: true, passwordType:'FOUNDPHONEPWD'},
                 {label: '提现密码修改', type: 'button', tip: '提现密码修改', eventName: 'passwordModify', showArrow: true, passwordType:'FOUNDACCOUNTPWD'},
             ],
             button: {
                 label: '退出登录',
                 eventName: 'loginOut'
             },
             userInfo: {}
        })
        const loginStatus = inject('loginStatus')
        const setLoginStatus = loginStatus.setIsLogin
        const visitor = getVisitor() == 'true'
        function fillInfo() {
            if (visitor) {
                toast('实名认证后才能查看')
                return
            }
            jumpTo(`/fillInfo?name=${state.userInfo.realName}&IdCard=${state.userInfo.cardNumber}`)
        }
        function passwordModify(index) {
            if (visitor) {
                toast('实名认证后才能查看')
                return
            }
            const passwordType = state.formItems[index].passwordType
            const { mobile, phone, userNo } = state.userInfo
            jumpTo(`/changePassword?phone=${mobile || phone}&cardNo=${userNo}&passwordType=${passwordType}`)
        }
        async function loginOut() {
            try {
                const result = await loginout()
                if (result.code == 0) {
                    toast('退出登录成功')
                    setLoginStatus(false)
                    setIsWhiteList(false)
                    removeVistor()
                    jumpTo('/')
                }
            } catch(e) {
                console.error(e)
            }
        }
        async function initTip() {
            try {
                const withdraw = await canWithdraw()
                state.formItems[1].tip = `${withdraw ? '' : ''}登录密码修改`
                // state.formItems[1].tip = `${withdraw ? '提现、' : ''}投注和登录密码修改`
            } catch(e) {
                console.error(e)
            }
        }
        // initTip()
        async function loadData() {
            try {
                const result = await getUserInfo()
                state.userInfo = result
                if (result.realName) {
                    state.formItems[0].tip = ''
                }
            } catch(e) {
                console.error(e)
            }
        }
        if (!visitor) {
            initTip()
            loadData()
        }
        return {
            ...toRefs(state),
            fillInfo,
            passwordModify,
            loginOut
        }
    },
}
</script>
<style lang='scss' scoped>
::v-deep .forms_items_item{
    label{
        width: 100px;
    }
}
</style>